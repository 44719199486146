import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  TableRow,
  TableCell,
  Divider,
  TableBody,
  Table,
} from '@material-ui/core';
import SubHeader from '../SubHeader';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { theme as koneTheme, theme } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ActivityDifferentiator,
  Assignment,
  InstallationSyncData,
  SubcontractorRecord,
} from '../../schemas';
import { useTranslation } from 'react-i18next';
import Context from '../../context';
import { useGetToken } from '../../hooks/useGetToken';
import { API_TYPE, get, put } from '../../helpers/fetch';
import { Installation } from '../../schemas';
import InstallationPlanQuestionsList from '../../containers/InstallationPlanQuestionsList';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { formatDate } from '../../helpers/formating';
import {
  deleteIndexedDBObject,
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
} from '../../helpers/indexedDB';
import { DeviationToSync, manualSyncFromUI } from '../../helpers/deviationOffline';
import { DatePicker } from '../DatePicker';

const useStyles = makeStyles(() =>
  createStyles({
    extraInfo: {
      margin: theme.spacing(5),
      maxWidth: '100vw',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    headerRow: {
      color: theme.palette.primary.main,
      paddingTop: theme.spacing(4),
      textTransform: 'uppercase',
    },
  })
);
interface QueryParams {
  networkNumber: string;
}

const InstallationPlan = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const { networkNumber } = useParams() as QueryParams;
  const classes = useStyles(koneTheme);
  const [currentUserRole] = useGetCurrentUserRole();
  const [isSubcontractor] = useIfSubcontractor();
  const [getTokenFunction] = useGetToken();
  const { installationData, updateInstallationData, updateIsLoading } =
    useContext(Context);
  const location = useLocation();
  const [newDate, setNewDate] = useState(true);
  const [inspectDate, setInspectDate] = useState(new Date(Date.now()));
  const isInstallationPlanPath = location.pathname.includes('installation-plan');
  async function syncData(networkNumber: string, accessToken: string) {
    const isMounted = true;
    try {
      const syncDataKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.SYNC_DATA
      );

      const deviationToSyncKey = generateIndexedDBKey(
        networkNumber,
        storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
      );

      const syncDataFromIndexedDb = await getIndexedDBObject<InstallationSyncData>(
        syncDataKey
      );

      const deviationSyncDataFromIndexedDB = await getIndexedDBObject<DeviationToSync[]>(
        deviationToSyncKey
      );
      if (syncDataFromIndexedDb) {
        await put(
          `v1/installations/${networkNumber}/sync`,
          accessToken,
          API_TYPE.APPLICATION,
          { ...syncDataFromIndexedDb, currentUserRole }
        );
      }
      if (deviationSyncDataFromIndexedDB?.length) {
        await manualSyncFromUI(networkNumber, accessToken);
      }
    } catch (e) {
      console.error('Error while navigating');
    } finally {
      if (isMounted) {
        await deleteIndexedDBObject(
          generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
        );
        await deleteIndexedDBObject(
          generateIndexedDBKey(
            networkNumber,
            storedIndexedDBObjectType.DEVIATIONS_TO_SYNC
          )
        );
      }
    }
  }

  const getSubcontractors = (
    role: ActivityDifferentiator,
    subcontractorRecords?: SubcontractorRecord[]
  ) => {
    const subcontractors = subcontractorRecords?.filter(
      (subcontractor) => subcontractor.activityDifferentiator === role
    );
    return subcontractors;
  };

  const getAssigees = (role: ActivityDifferentiator, assignees?: Assignment[]) => {
    const currentAssignees = assignees?.filter(
      (assignee) => assignee.activityDifferentiator === role
    );
    return currentAssignees;
  };

  const getStartAndTargetDate = (
    subcontractorRecords: SubcontractorRecord[],
    role: ActivityDifferentiator,
    assignees: Assignment[]
  ): (string | null)[] => {
    const subcontractors = getSubcontractors(role, subcontractorRecords);
    const currentAssignees = getAssigees(role, assignees);

    // Combine the two arrays
    const combinedArray = [
      ...(subcontractors || []).map((subcontractor) => ({
        startDate: subcontractor.plannedStartDate ? subcontractor.plannedStartDate : '',
        targetDate: subcontractor.plannedEndDate ? subcontractor.plannedEndDate : '',
      })),
      ...(currentAssignees || []).map((assignee) => ({
        startDate: assignee.assignmentStartDate ? assignee.assignmentStartDate : '',
        targetDate: assignee.assignmentEndDate ? assignee.assignmentEndDate : '',
      })),
    ];

    // Find the smallest start date
    const smallestStartDate = combinedArray.reduce<string | null>((earliest, item) => {
      const currentStartDate = new Date(item.startDate);
      return earliest === null || currentStartDate < new Date(earliest)
        ? item.startDate
        : earliest;
    }, null);

    //Find the largest target date
    const largestTargetDate = combinedArray.reduce<string | null>((largest, item) => {
      const currentTargetDate = item.targetDate
        ? new Date(item.targetDate)
        : new Date('1970-01-01T00:00:00.000Z');
      return largest === null || currentTargetDate > new Date(largest)
        ? item.targetDate
        : largest;
    }, null);

    return [smallestStartDate, largestTargetDate];
  };

  const [smallestStartDate, largestTargetDate] = getStartAndTargetDate(
    installationData?.subcontractors || [],
    ActivityDifferentiator.INST,
    installationData?.assignees || []
  );

  // Format the smallest and largest dates
  const formattedSmallestStartDate = smallestStartDate
    ? formatDate(smallestStartDate)
    : null;

  const formattedLargestTargetDate = largestTargetDate
    ? formatDate(largestTargetDate)
    : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (networkNumber) {
          isInstallationPlanPath && updateIsLoading(true);
          const accessToken = await getTokenFunction();
          const data: Installation | null = await get(
            `v1/installations/${networkNumber}`,
            accessToken
          );

          if (data) {
            updateInstallationData(data);
          }
          if (
            data?.inspectionDate === null ||
            data?.inspectionDate === undefined ||
            data?.inspectionDate === ''
          ) {
            setNewDate(true);
          } else {
            setInspectDate(new Date(data.inspectionDate));
            setNewDate(false);
          }
        }
      } catch (e) {
        console.error('Error while fetching installation data', e);
      } finally {
        isInstallationPlanPath && updateIsLoading(false);
      }
    };

    fetchData();
  }, [networkNumber]);

  const handleInstallationPlanForSupervisor = () => {
    try {
      history.push(`/${networkNumber}/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const isMounted = true;
    try {
      const token = await getTokenFunction();
      if (isMounted) {
        await put(
          `v1/installations/${networkNumber}/insDateSoldHours`,
          token,
          API_TYPE.APPLICATION,
          { type: 'inspectionDate', value: inspectionDate }
        );
      }
    } catch (error) {
      console.error('Error while updating inspection date', error);
    }
  };
  const handleInstallationPlanForInstaller = () => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    try {
      history.push(`${rootPath}/${networkNumber}/starting/pre-install-checklist`);
    } catch (error) {
      console.error('error while navigating', error);
    }
  };
  const handleDateChange = async (ISOFormattedDate: string) => {
    if (installationData) {
      installationData.inspectionDate = ISOFormattedDate;
    }
    if (ISOFormattedDate) {
      setInspectDate(new Date(ISOFormattedDate));
      await putInsDateSoldHrs(ISOFormattedDate);
    } else {
      setNewDate(true);
    }
  };

  const handleInstallationComplete = async (): Promise<void> => {
    const rootPath = isSubcontractor ? '/subcontractor' : '';
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();
      await syncData(networkNumber, accessToken);
      history.push(
        `${rootPath}/${networkNumber}/installation-plan/handover?questionSequence=0`
      );
    } catch (error) {
      console.error('error while navigating', error);
    } finally {
      updateIsLoading(false);
    }
  };

  return (
    <>
      {isInstallationPlanPath && (
        <SubHeader
          title={networkNumber}
          handleGoBackClick={() => {
            history.goBack();
          }}
        />
      )}
      {!isInstallationPlanPath && (
        <Card
          style={{
            maxWidth: '100%',
            margin: '0px auto',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
            // boxShadow: 3,
            border: '1px solid #ddd',
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Grid container spacing={2} style={{ paddingTop: 2 }}>
              {/* Target Date */}
              <Grid item xs={6} style={{ textAlign: 'left' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">{'Target'}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">{formattedLargestTargetDate}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Start Date */}
              <Grid item xs={6} style={{ textAlign: 'left' }}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Typography variant="subtitle1">
                      {t('installationPlanList.startDate')}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} style={{ padding: '2px', marginTop: '10px' }}>
                    <Typography variant="body1">{formattedSmallestStartDate}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Typography variant="subtitle1">Inspection</Typography>
                  </Grid>
                  <Grid item xs={5} style={{ padding: '2px', marginTop: '10px' }}>
                    <DatePicker
                      name="Inspection Date"
                      dateTime={inspectDate.toISOString()}
                      onDateSelected={handleDateChange}
                      label={t('supervisorNewInstallation.inspectionDate')}
                      newDate={newDate}
                      setNewDate={setNewDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.headerRow}>
              {isInstallationPlanPath ? (
                <Typography variant="h6">
                  {t('installationPlanList.installationPlanHeading')}
                </Typography>
              ) : (
                <Typography variant="h6">
                  {t('installationPlanList.installationTaskHeading')}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Divider />
      <InstallationPlanQuestionsList
        questionSets={installationData?.installationPlanQuestions}
        answers={installationData?.installationPlanAnswers}
        startDate={formattedSmallestStartDate}
        targetDate={formattedLargestTargetDate}
        networkNumber={networkNumber}
        installationData={installationData}
      />
      {isInstallationPlanPath ? (
        <Grid item className={classes.extraInfo} data-testid="pre-checklist">
          {currentUserRole === ActivityDifferentiator.SPV ? (
            <Button
              size="large"
              data-testid="supervisor-installation-plan-button"
              variant="contained"
              color="primary"
              onClick={handleInstallationPlanForSupervisor}
            >
              <Typography>
                {' '}
                {t('supervisorNewInstallation.saveInstallationPlanAndContinue')}{' '}
              </Typography>
            </Button>
          ) : (
            <Button
              size="large"
              data-testid="installer-installation-plan-button"
              variant="contained"
              color="primary"
              onClick={handleInstallationPlanForInstaller}
            >
              <Typography>
                {' '}
                {t('installationStarting.saveInstallationPlanAndContinue')}{' '}
              </Typography>
            </Button>
          )}
        </Grid>
      ) : (
        <>
          <Grid item className={classes.extraInfo} data-testid="pre-checklist">
            <Button
              size="large"
              data-testid="installer-installation-task-button"
              variant="contained"
              color="primary"
              disabled={
                (installationData?.installationPlanAnswers?.[0]?.answers?.filter(
                  (answer) => answer !== null
                ).length || 0) !==
                installationData?.installationPlanQuestions?.[0]?.questions.length
              }
              onClick={handleInstallationComplete}
            >
              <Typography> {t('installationStarting.installationComplete')} </Typography>
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default InstallationPlan;
