import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Typography, Grid, Box } from '@material-ui/core';
import { theme as koneTheme } from '@konecorp/ui-library';

import {
  Answer,
  Installation,
  isNewDeviation,
  PutAnswerPayload,
  Question,
} from '../../schemas';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { useTranslation } from 'react-i18next';
import CustomizedLocalizedQuestionText from '../../components/CustomizedLocalizedQuestionText';
import AnswerValueWithDeviation from '../AnswerValueWithDeviation';
import { useLocation } from 'react-router-dom';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import { DatePicker } from '../../components/DatePicker';
import { useGetToken } from '../../hooks/useGetToken';
import { API_TYPE, put } from '../../helpers/fetch';

export interface ChecklistQuestionProps {
  question: Question;
  answer?: Answer | null;
  questionSequence?: number;
  tableRowIndex: number;
  startDate: string | null;
  targetDate?: string | null;
  installationData?: Installation | null;
  arrayLength: number;
  networkNumber: string;
  saveAnswer?: (newAnswer: PutAnswerPayload) => Promise<void>;
  onCreateDeviation?: (deviation: CreateDeviationPayload) => Promise<void>;
  onEditDeviation?: (deviation: EditDeviationPayload) => Promise<void>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    evenRow: {
      backgroundColor: theme.palette.background.default,
    },
    oddRow: {
      backgroundColor: theme.palette.grey[100],
    },
    dateRow: {
      backgroundColor: theme.palette.grey[300],
      fontWeight: 'bold',
    },
    headerRow: {
      color: theme.palette.primary.main,
      paddingTop: theme.spacing(4),
      textTransform: 'uppercase',
    },
    dateFont: {
      fontWeight: 'bold',
    },
    textWrap: {
      flex: 1,
      wordBreak: 'break-word',
    },
    gridWidth: {
      width: '150px',
    },
    timeTakenGrid: {
      display: 'flex',
      justifyContent: 'center',
    },
    answerContainer: {
      flexWrap: 'wrap',
      justifyContent: 'end',
      paddingRight: theme.spacing(0.5),
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(5),
      },
    },
  })
);

const InstallationPlanQuestion = (props: ChecklistQuestionProps): JSX.Element => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const classes = useStyles(koneTheme);

  const {
    question,
    answer,
    questionSequence,
    tableRowIndex,
    startDate,
    targetDate,
    arrayLength,
    networkNumber,
    installationData,
    saveAnswer,
    onCreateDeviation,
    onEditDeviation,
  } = props;
  const [getTokenFunction] = useGetToken();
  const [newDate, setNewDate] = useState(true);
  const [inspectDate, setInspectDate] = useState(
    new Date(installationData?.inspectionDate ?? Date.now())
  );
  useEffect(() => {
    if (installationData?.inspectionDate !== null) {
      setNewDate(false);
    }
  }, []);
  const localizedQuestionText = getLocalizedText(i18n, question.text)?.text || '';
  const localizedQuestionTextWithoutWhiteSpace = localizedQuestionText
    .replaceAll(' ', '-')
    .trim();
  const isInstallationTaskPath = location.pathname.includes('installation-tasks');
  const putInsDateSoldHrs = async (inspectionDate: string): Promise<void> => {
    const token = await getTokenFunction();
    return await put(
      `v1/installations/${networkNumber}/insDateSoldHours`,
      token,
      API_TYPE.APPLICATION,
      { type: 'inspectionDate', value: inspectionDate }
    );
  };
  const handleDateChange = async (ISOFormattedDate: string) => {
    if (installationData) {
      installationData.inspectionDate = ISOFormattedDate;
    }
    if (ISOFormattedDate) {
      setInspectDate(new Date(ISOFormattedDate));
      await putInsDateSoldHrs(ISOFormattedDate);
    } else {
      setNewDate(true);
    }
  };
  return (
    <>
      {tableRowIndex === 0 && (
        <>
          {!isInstallationTaskPath && (
            <TableRow>
              <TableCell className={classes.dateRow}>
                <Grid container alignItems="flex-start">
                  <Grid item className={classes.gridWidth} data-testid={'start-date'}>
                    <Typography className={classes.dateFont}>
                      {startDate ? startDate : 'NOT AVAILABLE'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.textWrap}
                    data-testid="start-date-heading"
                  >
                    <Typography className={classes.dateFont}>
                      {t('installationPlanList.startDate')}
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
      {tableRowIndex !== 10 && (
        <TableRow>
          <TableCell
            className={tableRowIndex % 2 === 0 ? classes.evenRow : classes.oddRow}
            data-testid={
              tableRowIndex % 2 === 0
                ? `even-question-row-${localizedQuestionTextWithoutWhiteSpace}`
                : `odd-question-row-${localizedQuestionTextWithoutWhiteSpace}`
            }
          >
            <Grid container alignItems="flex-start">
              <Grid item className={classes.textWrap}>
                <CustomizedLocalizedQuestionText
                  localaizedQuestionText={localizedQuestionText}
                />
              </Grid>
              {isInstallationTaskPath &&
                onCreateDeviation &&
                onEditDeviation &&
                saveAnswer && (
                  <Grid container item xs={5} justify="flex-end">
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.answerContainer}
                    >
                      {question.valueType === 'yes_no' && (
                        <AnswerValueWithDeviation
                          answer={answer?.value}
                          question={question}
                          questionSequence={questionSequence || 0}
                          isReadOnly={false}
                          onAnswer={async (newAnswer, deviation) => {
                            if (deviation) {
                              if (isNewDeviation(deviation)) {
                                await onCreateDeviation(
                                  deviation as CreateDeviationPayload
                                );
                              } else {
                                await onEditDeviation(deviation as EditDeviationPayload);
                              }
                            }

                            await saveAnswer(newAnswer);
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
            </Grid>
          </TableCell>
        </TableRow>
      )}
      {tableRowIndex === arrayLength - 1 && !isInstallationTaskPath && (
        <>
          <TableRow>
            <TableCell className={classes.dateRow}>
              <Grid container alignItems="flex-start">
                <Grid item className={classes.gridWidth}>
                  <Typography className={classes.dateFont}>
                    {targetDate ? targetDate : 'NOT AVAILABLE'}
                  </Typography>
                </Grid>
                <Grid item className={classes.textWrap}>
                  <Typography className={classes.dateFont}>
                    {t('installationPlanList.targetDate')}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.oddRow}>
              <Grid container alignItems="flex-start">
                <Grid item className={classes.gridWidth}>
                  {installationData?.inspectionDate ? (
                    <DatePicker
                      name="Inspection Date"
                      dateTime={inspectDate.toISOString()}
                      onDateSelected={handleDateChange}
                      label={t('supervisorNewInstallation.inspectionDate')}
                      newDate={newDate}
                      setNewDate={setNewDate}
                    />
                  ) : (
                    <Typography className={classes.dateFont}>
                      {'NOT AVAILABLE'}
                    </Typography>
                  )}
                </Grid>
                <Grid item className={classes.textWrap}>
                  <Typography className={classes.dateFont}>
                    {t('installationPlanList.inspectionDate')}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </>
      )}
      {tableRowIndex === 10 && (
        <TableRow>
          <TableCell
            className={tableRowIndex % 2 === 0 ? classes.evenRow : classes.oddRow}
            data-testid={
              tableRowIndex % 2 === 0
                ? `even-question-row-${localizedQuestionTextWithoutWhiteSpace}`
                : `odd-question-row-${localizedQuestionTextWithoutWhiteSpace}`
            }
          >
            <Grid container alignItems="flex-start">
              <Grid item className={classes.textWrap}>
                <CustomizedLocalizedQuestionText
                  localaizedQuestionText={localizedQuestionText}
                />
              </Grid>
              {isInstallationTaskPath &&
                onCreateDeviation &&
                onEditDeviation &&
                saveAnswer && (
                  <Grid container item xs={5} justify="flex-end">
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.answerContainer}
                    >
                      {question.valueType === 'yes_no' && (
                        <AnswerValueWithDeviation
                          answer={answer?.value}
                          question={question}
                          questionSequence={questionSequence || 0}
                          isReadOnly={false}
                          onAnswer={async (newAnswer, deviation) => {
                            if (deviation) {
                              if (isNewDeviation(deviation)) {
                                await onCreateDeviation(
                                  deviation as CreateDeviationPayload
                                );
                              } else {
                                await onEditDeviation(deviation as EditDeviationPayload);
                              }
                            }

                            await saveAnswer(newAnswer);
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default InstallationPlanQuestion;
